<template>
  <section class="section is-main-section">
    <card-component
      title="生成單據"
      icon="ballot"
    >      <b-modal
      v-model="isMainAddNewActive"
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal>
      <template #default="props" >
        <pop-up-form @close="props.close" :formConfig="popUpConfig"
                     :addNewURL="popUpUpdateLink" v-on:tabChange="tabChange" v-on:submitForm="submitForm"  ref="popupForm" :key="popKey" :detailViewLink="detailViewLink" :loadFormData="loadFormData"></pop-up-form>
      </template>
    </b-modal>
      <dodo-form :hasDetail="true" :formData="searchData" :formType="'search'" :submitForm="submitForm" :newButtonLabel="'group_to_intersales'"  v-on:addNewClick="addNewClick" ></dodo-form>
      <result :searchData="searchResult" ref="result" ></result>
    </card-component>
  </section>
</template>
<script>
import CardComponent from '@/components/CardComponent.vue'
import Result from '@/components/General/Result.vue'
import DodoForm from '@/components/General/form.vue'
import api from '@/api.js'
import PopUpForm from '@/ERP/innerSales/PopUpForm.vue'

export default {
  name: 'innerSalesCreate',
  components: {
    CardComponent,
    Result,
    DodoForm,
    PopUpForm,
  },
  methods: {
    parentFormSubmit: function(){
      console.log('submitthisright')
    },
    submitForm: function(json){
      this.$refs.result.loadGrid(json)
      this.detailKey = 0
    },
    addNewClick(){
      // new
      this.isMainAddNewActive = true
    },
    loadFormData: async function(json){
      const id = []
      this.$refs.result.checkedRows.forEach(element => {
        id.push(element.arrival_detail_id)
      })
      const formData = new FormData()
      formData.append('postData', json)
      formData.append('arrivalDetailId', String(id))
      await api.post(this.updateLink, formData)
      this.$router.push({ path: '/innerSales', replace: true })
    },
    async tabChange(row){
    }
  },
  async created() {
    api.config('config/innerSalesCreate.json', {
    }).then( function(response){
      this.searchData = response.data.search
      this.detailData = response.data.detail
      this.$refs.result.columns = response.data.columns
      this.$refs.result.url = response.data.urlSearch
      this.viewLink = response.data.urlShowDetail
      this.updateLink = response.data.urlUpdateDetail
      this.detailColumns = response.data.detailColumns
      this.popUpConfig = response.data.detail
      this.addNewConfig = response.data.detail
      this.childConfig = response.data.child
      this.addNewURL = response.data.urlMasterAddNew
      this.popUpUpdateLink = response.data.urlMasterAddNew
      this.childAddNewLink = response.data.urlChildAddNew
      this.primaryKey = response.data.primaryKey
      this.urlChildAddUpdate = response.data.urlChildAddUpdate
      this.detailViewLink = response.data.urlChildView
    }.bind(this))
  },
  data(){
    return {
      activeTab: 0,
      showDetail: false,
      showCreate: false,
      searchData: [],
      detailData: [],
      searchResult: [],
      detailKey: 0,
      popKey: 0,
      viewLink: '',
      detailViewLink: '',
      updateLink: '',
      detailColumns: [],
      detailGridlData: [],
      isMainAddNewActive: false,
      addNewURL: '',
      popUpConfig: [],
      childConfig: [],
      addNewConfig: [],
      childAddNewLink: '',
      popUpUpdateLink: '',
      primaryKey: '',
      currentRow: 0,
      popType: '',
      detailRow: 0,
    }
  },
}
</script>
