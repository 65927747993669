<template>
  <section class="section is-main-section">
    <card-component
      title="單據"
      icon="ballot"
    >
      <b-modal
        v-model="isMainAddNewActive"
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        close-button-aria-label="Close"
        @after-enter="modalOpen"
        aria-modal>
        <template #default="props" >
          <pop-up-form @close="props.close" :formConfig="popUpConfig"
                       :addNewURL="popUpUpdateLink" v-on:tabChange="tabChange" v-on:submitForm="submitForm"  ref="popupForm" :key="popKey" :detailViewLink="detailViewLink" :loadFormData="loadFormData"></pop-up-form>
        </template>
      </b-modal>
      <b-tabs type="is-boxed"  v-model="activeTab" >
        <b-tab-item :label="$t('en.list')" icon="table">
          <dodo-form :hasDetail="true" :submitForm="submitForm" :formData="searchData" :formType="'search'" v-on:addNewClick="addNewClick" :newButtonLabel="'add_new'"></dodo-form>
          <result :searchData="searchResult" ref="result" v-on:tabChange="tabChange"></result>
        </b-tab-item>
        <b-tab-item :label="$t('en.detail')" icon="note-plus-outline" :visible="showDetail">
          <dodo-form :hasDetail="true" :submitForm="submitForm" v-on:submitForm="submitForm" :formData="detailData"  v-on:addNewClick="addNewClick" :downloadLink="'interSale/exportInvoice?interSaleId='+currentRow"
                     :viewLink="viewLink" :updateLink="updateLink" :formType="'detail'" ref="detailForm" :loadFormData="loadFormData" :key="detailKey" :newButtonLabel="'add_new'" :payStatus="payStatus"></dodo-form>
          <detail-result :detailColumns="detailColumns" :detailGridlData="detailGridlData"  v-on:addNewClick="addNewClick" v-on:tabChange="tabChange" :currentRow="currentRow" v-on:submitForm="submitForm"></detail-result>
        </b-tab-item>
        <b-tab-item label="Create" icon="note-text-outline" :visible="showCreate"></b-tab-item>
      </b-tabs>
    </card-component>
  </section>
</template>

<script>
  import CardComponent from '@/components/CardComponent.vue'
  import Result from '@/components/General/Result.vue'
  import DodoForm from '@/components/General/innerSalesForm.vue'
  import DetailResult from '@/components/General/DetailResult.vue'
  import PopUpForm from '@/components/General/PopUpForm.vue'
  import api from '@/api.js'

  export default {
    name: 'innerSales',
    methods: {
      modalOpen(){
        if (this.detailRow !== 0) {
          this.$refs.popupForm.showDetailForm(this.detailRow)
        }
        // console.log(this.$refs.popupForm.showDetailForm(this.currentRow))
        // this.popUpConfig = this.childConfig
      },
      submitForm: function(json){
        this.$refs.result.loadGrid(json)
        this.detailRow = 0
      },
      loadFormData: function(json){
        if ((json.master['status']) === 'pending'){
          this.payStatus = '部份己付'
         } else if ((json.master['status']) === 'partial') {
          this.payStatus = '己付'
        } else if ((json.master['status']) === 'paid'){
          this.payStatus = '未付'
        }
        if ( this.detailRow === 0 ){
          const detailData = this.detailData
          for (const x in detailData) {
            const fieldName = detailData[x].fieldName
            detailData[x].value = json.master[fieldName]
          }
          this.detailData = detailData
          this.detailKey += 1
          this.detailGridlData = json.detail
        } else {
          const popUpConfig = this.popUpConfig
          for (const x in popUpConfig) {
            const fieldName = popUpConfig[x].fieldName
            popUpConfig[x].value = json.master[fieldName]
          }
          this.popUpConfig = popUpConfig
          this.popKey += 1
        }
        // force repaint
      },
      addNewClick(formType, row, detailRow){
        if (formType === 'search'){
          for (const x in this.addNewConfig) {
            this.addNewConfig[x].value = null
          }
          this.popUpConfig = this.addNewConfig
          this.popUpUpdateLink = this.addNewURL
        } else {
          for (const x in this.childConfig) {
            if (this.childConfig[x].fieldName === this.primaryKey){
              this.childConfig[x].value = this.currentRow
            } else {
              this.childConfig[x].value = null
            }
          }
          this.popUpConfig = this.childConfig
          this.popUpUpdateLink = this.childAddNewLink
        }
        if (formType === 'detailUpdate') {
          this.popUpUpdateLink = this.urlChildAddUpdate
          this.popType = 'update'
          this.currentRow = row
          this.detailRow = detailRow
        } else {
          this.popType = 'addNew'
          this.currentRow = 0
          this.detailRow = 0
        }
        this.popKey += 1
        this.isMainAddNewActive = true
      },
      async tabChange(row){
        this.activeTab = 1
        this.showDetail = true
        await this.$refs.detailForm.showDetailForm(row, this.viewLink)
        this.currentRow = row
      }
    },
    async created() {
      api.config('config/innerSales.json', {
      }).then( function(response){
        this.searchData = response.data.search
        this.detailData = response.data.detail
        this.$refs.result.columns = response.data.columns
        this.$refs.result.url = response.data.urlSearch
        this.viewLink = response.data.urlShowDetail
        this.updateLink = response.data.urlUpdateDetail
        this.detailColumns = response.data.detailColumns
        this.popUpConfig = response.data.detail
        this.addNewConfig = response.data.detail
        this.childConfig = response.data.child
        this.addNewURL = response.data.urlMasterAddNew
        this.popUpUpdateLink = response.data.urlMasterAddNew
        this.childAddNewLink = response.data.urlChildAddNew
        this.primaryKey = response.data.primaryKey
        this.urlChildAddUpdate = response.data.urlChildAddUpdate
        this.detailViewLink = response.data.urlChildView
        if (this.$route.query.id !== undefined){
          this.tabChange(this.$route.query.id)
          this.$router.replace({ 'query': null })
        }
      }.bind(this))
    },
    data(){
      return {
        activeTab: 0,
        showDetail: false,
        showCreate: false,
        searchData: [],
        detailData: [],
        searchResult: [],
        detailKey: 0,
        popKey: 0,
        viewLink: '',
        detailViewLink: '',
        updateLink: '',
        detailColumns: [],
        detailGridlData: [],
        isMainAddNewActive: false,
        addNewURL: '',
        popUpConfig: [],
        childConfig: [],
        addNewConfig: [],
        childAddNewLink: '',
        popUpUpdateLink: '',
        primaryKey: '',
        currentRow: 0,
        popType: '',
        detailRow: 0,
        payStatus: ''
      }
    },
    components: {
      CardComponent,
      Result,
      DodoForm,
      DetailResult,
      PopUpForm,
    }
  }
</script>
