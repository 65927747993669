<template>
  <div>
    <p>{{$t('en.message')}}</p>
    <input type="text" :placeholder="$t('en.message')">
    <b-button @click='changeLanguage'>切換語言</b-button>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
  export default {
    name: 'upload',
    data() {
      return {
        lang: Cookies.get('language') || 'en',
      }
    },
    methods: {
      changeLanguage () {
        console.log(this.$i18n.locale)
        const lang = this.$i18n.locale === 'en' ? 'hk' : 'en'
        this.$i18n.locale = lang
        Cookies.set('language', lang)
        alert(this.$t('en.message'))
      }
    }
  }
</script>
