<template>
  <b-modal
    v-model="isMainAddNewActive"
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    :aria-label="$t('en.add_new')"
    close-button-aria-label="Close"
    @after-enter="modalOpen"
    aria-modal>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{$t('en.add_new')}}</p>
      </header>
      <section class="modal-card-body pb-0 pt-2">
        <form class="mt-5" ref="form" @submit.prevent = "submit" v-on:keydown.enter.prevent="submit">
        <dodoform purpose="addNew" :moduleName="this.moduleName" ref="dodoForm" :parentId="currentRow"></dodoform>
        </form>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :label="$t('en.submit')"
          type="is-info"
          @click="saveData" />
        <b-button
          :label="$t('en.close')"
          @click="close" />
      </footer>
    </div>
  </b-modal>
</template>
<script>
  import api from '@/api.js'
  import Dodoform from '@/components/dodoComponents/dodoForm.vue'
  export default {
    name: 'dodoFormAddNew',
    components: {
      Dodoform
    },
    methods: {
      modalOpen: function(){
      },
      load: function(){
        this.isMainAddNewActive = true
      },
      close: function(){
        this.isMainAddNewActive = false
      },
      setParent (row){
        this.currentRow = row
      },
      saveData: async function (){
        const form = this.$refs.form
        let returnId = 0
        const object = {}
        let isFormPassed = true
        this.$refs.dodoForm.$refs.formElement.forEach(function (item){
          if ( item.$children.length > 0 ){
            if ( !item.$children[0].checkHtml5Validity()){
              isFormPassed = false
            }
          }
        })
        if ( isFormPassed ) {
          for ( let i = 0; i < form.elements.length; i++ ) {
            const e = form.elements[i]
            if (e.name !== '') {
              object[e.name] = e.value
            }
          }
          const json = JSON.stringify(object)
          const formData = new FormData()

          formData.append('postData', json)
          const resultData = await api.post(this.moduleName + '/saveData', formData)
          // this.$emit('submitForm', '')
          const msg = this.$t('en.' + resultData.data.message)
          this.$buefy.snackbar.open({
            message: msg,
            queue: false
          })
          returnId = resultData.data.return
          if ( returnId > 0 ){
            this.$emit('searchSubmit')
            this.close()
          }
          return returnId
        } else {
          return 0
        }
      }
    },
    data(){
      return {
        isMainAddNewActive: false,
        currentRow: 0
      }
    },
    props: ['moduleName']
  }
</script>
