<template>
    <card-component
      :title="$t('en.' + moduleName)"
      icon="ballot"
    >
  <b-table
    :loading="isLoading"
    :data="data"
    :bordered="true"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-simple="isPaginationSimple"
    :pagination-position="paginationPosition"
    :default-sort-direction="defaultSortDirection"
    :pagination-rounded="isPaginationRounded"
    :sort-icon="sortIcon"
    :sort-icon-size="sortIconSize"
    default-sort="user.first_name"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    :page-input="hasInput"
    :pagination-order="paginationOrder"
    :page-input-position="inputPosition"
    :debounce-page-input="inputDebounce"
    @click="checkedRows = []"
    :checked-rows.sync="checkedRows"
    checkable
    striped>
    <b-table-column v-for="item in columns" :key="item.id"  sortable :label="$t('en.'+ item.field)"  :header-class="item.type==null ? 'customer-table-header' : 'checkbox-cell'"
                    :field="item.field" v-slot="props" >
      <a v-if="item.linkid!=null" @click="rowClick(props.row[item.linkid])" >
        {{ props.row[item.field]}}
      </a>
      <span v-else-if="item.type=='delete'">
           <b-button type="is-danger"
                     icon-right="delete" @click="deleteClick(item.field, item.url, props.row)"/>
        </span>
      <span v-else>
          {{ props.row[item.field]}}
        </span>
    </b-table-column>
  </b-table>
    </card-component>
</template>
<script>
  import api from '@/api.js'
  import CardComponent from '@/components/CardComponent.vue'
  export default {
    props: ['searchData', 'columnsSet', 'moduleName', 'searchSubmit'],
    methods: {
      loadGrid: async function(parentId){
        this.isLoading = true
        this.parentId = parentId
        const resultData = await api.get(this.moduleName + '/getByParentId?id=' + parentId)
        this.data = resultData.data.list
        this.isLoading = false
      },
      deleteClick: function(idName, url, row){
        const idValue = row[idName]
        this.$buefy.dialog.confirm({
          title: this.$t('en.deleting'),
          message: '<span style>' + this.$t('en.confirm_delete') + '</span>',
          confirmText: this.$t('en.deleting'),
          cancelText: this.$t('en.cancel'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.confirmDelete(idName, url, idValue)
        })
      },
      async confirmDelete(idName, url, idValue){
        await api.get(url + '?' + idName + '=' + idValue)
          this.$buefy.snackbar.open({
            message: 'Done',
            queue: false
          })
        this.loadGrid(this.parentId)
        this.searchSubmit()
      },
      rowClick(row){
        this.$emit('searchRowClick', row)
      }
    },
    watch: {
      data: function(val) {
        this.isLoading = false
      }
    },
    components: {
      CardComponent,
    },
    data() {
      return {
        data: [],
        columns: [],
        isLoading: false,
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        postData: {},
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',
        checkedRows: [],
        parentId: 0,
      }
    }
  }
</script>
<style scoped>
  a:hover{
    text-decoration: underline;
  }
</style>
