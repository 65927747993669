<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Record Detail</p>
    </header>
    <section class="modal-card-body pb-0 pt-2">
      <dodo-form :hasDetail="false" :formData="formConfig" :formType="'addNew'" :updateLink="addNewURL" ref="form" :viewLink="detailViewLink" :loadFormData="loadFormData"></dodo-form>
    </section>
    <footer class="modal-card-foot">
      <b-button
        :label="$t('en.submit')"
        type="is-info"
        @click="saveData" />
      <b-button
        :label="$t('en.close')"
        @click="$emit('close')" />
    </footer>
  </div>
</template>
<script>
  import DodoForm from '@/ERP/contract/form.vue'
  export default {
    name: 'popupform',
    props: ['formConfig', 'addNewURL', 'submitForm', 'detailViewLink', 'loadFormData'],
    methods: {
      async saveData(){
        const returnId = await this.$refs.form.submit(this.currentRow)

        if ( returnId > 0) {
          this.$emit('tabChange', returnId)
          this.$emit('submitForm', '')
          this.$emit('close')
        }
      },
      showDetailForm(row){
        this.$refs.form.showDetailForm(row)
        // console.log(row)
      },
    },
    components: {
      DodoForm,
    }
  }
</script>
