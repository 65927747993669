<template>
  <form class="mt-5" ref="form" @submit.prevent = "submit" v-on:keydown.enter.prevent="submit">
    <div class="columns block is-multiline mb-0">
      <b-field   v-for="item in formData" :key="item.id"  :label="item.label" label-position="on-border"
                 :class="item.label!='' ? 'column is-6' : 'column is-6 p-0'"  expanded ref="formElement">
        <b-input v-if="item.formType==='input'" :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName" :v-bind="item.fieldName"
                 :ref="item.fieldName" :value="item.value" :required="item.searchType==null && item.required!=null ? true : false" :disabled="item.disabled"></b-input>
        <b-select v-if="item.formType==='select'" :placeholder="item.label" :required="item.searchType==null && item.required!=null ? true : false"
                  :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName" :value="item.value" :disabled="item.disabled">
          <option v-for="data in item.data" :key="data.value" :value="data.value">{{data.name}}</option>
        </b-select>
        <load-option v-if="item.formType==='dSelect'" :url="item.url"  :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName" :value="item.value"></load-option>
        <b-datepicker v-if="item.formType==='date' && item.value!=null" :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName"  icon="calendar-today"  locale="en-US"  :value="new Date(item.value)"></b-datepicker>
        <b-datepicker v-if="item.formType==='date' && item.value==null" :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName"  icon="calendar-today"  locale="en-US" ></b-datepicker>
        <input v-if="item.formType==='id' && item.value!=null" type="hidden" :name="item.fieldName" :value="item.value">
        <input v-if="item.formType==='parentId'" type="hidden" :name="item.fieldName" :value="item.value">
      </b-field>
    </div>
    <b-field grouped v-if="this.formType!=='addNew'">
      <div class="control">
        <b-button
          type="is-info"
          @click="submit"
        >
          <span v-if="this.formType=='search'">{{$t('en.search')}}</span>
          <span v-else> {{$t('en.submit')}}</span>
        </b-button>
      </div>
      <div class="control" v-if="this.hasDetail">
        <b-button
          type="is-danger"
          @click="addNewClick"
        >
          {{$t('en.'+this.newButtonLabel)}}
        </b-button>
      </div>
      <div class="control" v-if="this.formType!='search'">
        <b-button
          type="is-info"
          @click="download"
        >
          下載單據
        </b-button>
      </div>
      <div class="control" v-if="this.formType!='search'">
        <b-button
          type="is-info"
          @click="downloadpacking"
        >
          下載包裝
        </b-button>
      </div>
      <div class="control" v-if="this.formType!='search'">
        <b-button
          type="is-info"
          @click="downloadcontract"
        >
          下載合同
        </b-button>
      </div>
      <div class="control" v-if="this.formType!='search'">
        <b-button
          type="is-info"
          @click="changeStatus"
          ref="payBtn"
          :key="payKey"
          :label="payStatus"
        >
        </b-button>
      </div>
      <div class="control">
        <b-button
          type="is-info is-outlined"
          @click="reset"
        >
          {{$t('en.reset')}}
        </b-button>
      </div>
    </b-field>
    <hr v-if="this.formType!=='addNew'" class="mt-1 mb-3">
  </form>
</template>
<script>
  import api from '@/api.js'
  import loadOption from '@/components/General/loadOption.vue'
  export default {
    name: 'dodoForm',
    components: {
      loadOption
    },
    methods: {
      download: function(){
        this.formData.forEach(function (item){
          if (item.fieldName === 'interSaleId'){
            window.open( api.publicURL + 'interSale/exportInvoice?interSaleId=' + item.value, '_blank' )
          }
        })
      },
      downloadpacking: function(){
        this.formData.forEach(function (item){
          if (item.fieldName === 'interSaleId'){
            window.open( api.publicURL + 'interSale/exportPasking?interSaleId=' + item.value, '_blank' )
          }
        })
      },
      downloadcontract: function(){
        this.formData.forEach(function (item){
          if (item.fieldName === 'interSaleId'){
            window.open( api.publicURL + 'interSale/exportContract?interSaleId=' + item.value, '_blank' )
          }
        })
      },
      changeStatus: async function(){
        let value = ''
        let id = 0
        this.formData.forEach(function (item){
          if (item.fieldName === 'status'){
            if (item.value === 'pending') {
              value = 'partial'
            } else if (item.value === 'partial') {
                value = 'paid'
            } else {
              value = 'pending'
            }
          } else if (item.fieldName === 'interSaleId'){
            id = item.value
          }
        })
        await api.get('/interSale/changeStatus?id=' + id + '&status=' + value)
        this.showDetailForm(id)
      },
      showDetailForm: async function(row, viewLink){
        if (viewLink === null){
          viewLink = this.viewLink
        }
        this.currentRow = row
        const resultData = await api.get(viewLink + '?id=' + row)
        this.formDetailData = resultData.data.master
        this.loadFormData(resultData.data)
      },
      addNewClick(){
        this.$emit('addNewClick', this.formType)
      },
      async submit() {
        const form = this.$refs.form
        let returnId = 0
        const object = {}
        let isFormPassed = true
        this.$refs.formElement.forEach(function (item){
          if ( item.$children.length > 0 ){
            if ( !item.$children[0].checkHtml5Validity()){
              isFormPassed = false
            }
          }
        })
        if ( isFormPassed ) {
          for ( let i = 0; i < form.elements.length; i++ ) {
            const e = form.elements[i]
            if (e.name !== '') {
              object[e.name] = e.value
            }
          }
          const json = JSON.stringify(object)
          const formData = new FormData()
          if (this.formType === 'search') {
            formData.append('searchData', json)
            this.submitForm(formData)
          } else if (this.formType === 'detail' || this.formType === 'addNew'){
            formData.append('postData', json)
            const resultData = await api.post(this.updateLink, formData)
            this.$emit('submitForm', '')
            this.$buefy.snackbar.open({
              message: resultData.msg,
              queue: false
            })
            returnId = resultData.data.result
          }
          return returnId
        } else {
          return 0
        }
      },
      reset(){
        const form = this.$refs.form
        for ( let i = 0; i < form.elements.length; i++ ) {
          const e = form.elements[i]
          e.value = ''
        }
      }
    },
    data() {
      return {
        selected: new Date(),
        formDetailData: {},
        isLoading: false,
        isMainAddNewActive: false,
        currentRow: 0,
        payKey: 0
      }
    },
    props: ['submitForm', 'formData', 'formType', 'loadFormData', 'updateLink', 'viewLink', 'hasDetail', 'primaryKey', 'popType', 'newButtonLabel', 'payStatus']
  }
</script>
