<template>
  <card-component
    title="Sales Order"
    icon="ballot"
  >
    <b-table :data="data" :columns="columns" :bordered="true" striped></b-table>
  </card-component>
</template>

<script>
  import CardComponent from '@/components/CardComponent.vue'
  export default {
    name: 'sampleData',
    components: {
      CardComponent,
    },
    data() {
      return {
        data: [
          { 'id': 'XD-EP2022-02-0039', 'first_name': 'Jesse', 'last_name': 'Simmons', 'date': '2016-10-15 13:43:27', 'gender': 'Male', 'payStatus': '50% paid', 'status': '還有一票貨未做PO' },
          { 'id': 'XD-EP2022-02-0041', 'first_name': 'John', 'last_name': 'Jacobs', 'date': '2016-12-15 06:00:53', 'gender': 'Male' },
        ],
        columns: [
          {
            field: 'id',
            label: '單據編號',
            headerClass: 'customer-table-header',
          },
          {
            field: 'first_name',
            label: '往來單位',
            headerClass: 'customer-table-header',
          },
          {
            field: 'last_name',
            label: '出貨倉庫',
            headerClass: 'customer-table-header',
          },
          {
            field: 'date',
            label: '單據日期',
            centered: true,
            headerClass: 'customer-table-header',
          },
          {
            field: 'gender',
            label: '經手人',
            headerClass: 'customer-table-header',
          },
          {
            field: 'payStatus',
            label: '支付狀態',
            headerClass: 'customer-table-header',
          },
          {
            field: 'status',
            label: '狀態',
            headerClass: 'customer-table-header',
          }
        ]
      }
    }
  }
</script>
