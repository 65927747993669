<template>
    <div class="columns block is-multiline mb-0">
      <b-field   v-for="item in formData" :key="item.id"  :label="$t('en.'+ item.fieldName)" label-position="on-border"
                 :class="item.label!='' ? 'column is-6' : 'column is-6 p-0'"  expanded ref="formElement">
        <b-input v-if="item.formType==='input'" :name="purpose=='search' ? item.fieldName+'--'+item.searchType : item.fieldName" :v-bind="item.fieldName"
                 :ref="item.fieldName" :value="item.value" :required="purpose!='search' && item.required!=null ? true : false"></b-input>
        <b-datepicker v-if="item.formType==='date' && item.value!=null" :name="purpose=='search' ? item.fieldName+'--'+item.searchType : item.fieldName"  icon="calendar-today"  locale="en-US"  :value="new Date(item.value)"></b-datepicker>
        <b-datepicker v-if="item.formType==='date' && item.value==null" :name="purpose=='search' ? item.fieldName+'--'+item.searchType : item.fieldName"  icon="calendar-today"  locale="en-US" ></b-datepicker>
        <b-select v-if="item.formType==='select'" :placeholder="item.label" :required="item.searchType==null && item.required!=null ? true : false"
                  :name="purpose=='search' ? item.fieldName+'--'+item.searchType : item.fieldName" :value="item.value">
          <option v-for="data in item.data" :key="data.value" :value="data.value">{{data.name}}</option>
        </b-select>
        <load-option v-if="item.formType==='dSelect'" :url="item.url"  :name="purpose=='search' ? item.fieldName+'--'+item.searchType : item.fieldName" :value="item.value"
                     :required="purpose!='search' && item.required!=null ? true : false"></load-option>
        <dodo-select v-if="item.formType==='aSelect'" :url="item.url" :name="item.fieldName" :fieldValue="item.value"></dodo-select>
        <input v-if="item.formType==='parentId'" type="hidden" :name="item.fieldName" :value="parentId" :v-bind="parentId">
      </b-field>
    </div>
</template>
<script>
  import api from '@/api.js'
  import dodoSelect from '@/components/dodoComponents/dodoOption.vue'
  import loadOption from '@/components/General/loadOption.vue'
  export default {
    name: 'dodoForm',
    components: {
      dodoSelect,
      loadOption,
    },
    async created() {
      let purpose = this.purpose
      if ( this.purpose === 'edit' || this.purpose === 'addNew'){
        purpose = 'form'
      }
      api.config('config/' + this.moduleName + '/' + purpose + '.json', {
      }).then( function(response) {
        if (this.purpose !== 'edit') {
          for (const x in response.data.data) {
            if (!response.data.data[x].editOnly){
              this.formData.push(response.data.data[x])
            }
          }
        } else if (this.purpose === 'edit') {
          for (const x in response.data.data) {
              this.formData.push(response.data.data[x])
          }
          this.key = response.data.key
        }
      }.bind(this))
    },
    data(){
      return {
        formData: [],
        key: 0,
      }
    },
    props: ['moduleName', 'purpose', 'parentId']
  }
</script>
