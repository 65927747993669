<template>
  <section class="section is-main-section">
    <card-component
      :title="$t('en.'+ moduleName)"
      icon="ballot"
    ><dodo-form-add-new ref="addNewForm" :moduleName="moduleName" v-on:searchSubmit="searchSubmit" ></dodo-form-add-new>
      <dodo-form-add-new ref="addNewDetailForm" :moduleName="this.moduleName + 'Detail'" v-on:searchSubmit="searchSubmit" :row="currentRow"></dodo-form-add-new>
      <b-tabs type="is-boxed"  v-model="activeTab" >
        <b-tab-item :label="$t('en.list')" icon="table">
          <DodoFormSearch :moduleName="moduleName" :searchSubmit="searchSubmit" :addNew="addNew"></DodoFormSearch>
          <dodo-grid :searchData="searchResult" ref="searchGrid" :moduleName="moduleName" v-on:searchRowClick="searchRowClick"></dodo-grid>
        </b-tab-item>
        <b-tab-item :label="$t('en.detail')" icon="note-plus-outline" :visible="showDetail" >
          <dodo-edit ref="updateForm" :moduleName="moduleName" :searchSubmit="searchSubmit" v-on:addNewDetail="addNewDetail"></dodo-edit>
          <hr class="mt-1 mb-3">
          <dodo-grid-detail  ref="detailGrid" :searchSubmit="searchSubmit" :moduleName="this.moduleName + 'Detail'"></dodo-grid-detail>
        </b-tab-item>
      </b-tabs>
    </card-component>
  </section>
</template>
<script>
  import CardComponent from '@/components/CardComponent.vue'
  import DodoFormSearch from '@/components/dodoComponents/dodoFormSearch.vue'
  import DodoFormAddNew from '@/components/dodoComponents/dodoFormAddNew.vue'
  import DodoGrid from '@/components/dodoComponents/dodoGrid.vue'
  import DodoGridDetail from '@/components/dodoComponents/dodoGridDetail.vue'
  import DodoEdit from '@/components/dodoComponents/dodoFormEdit.vue'
  import api from '@/api.js'

  export default {
    name: 'lead',
    data() {
      return {
        activeTab: 0,
        moduleName: this.$options.name,
        searchData: [],
        searchResult: [],
        showDetail: false,
        currentRow: 0,
      }
    },
    methods: {
      addNewDetail (row){
        this.$refs.addNewDetailForm.setParent(row)
        this.$refs.addNewDetailForm.load()
      },
      searchSubmit: async function(postData){
        this.$refs.searchGrid.loadGrid(postData)
        if ( this.activeTab === 1){
          await this.$refs.detailGrid.loadGrid(this.currentRow)
        }
      },
      addNew: function(){
        this.$refs.addNewForm.load()
      },
      close: function(){
        this.$refs.addNewForm.close()
      },
      searchRowClick: async function(row){
        this.activeTab = 1
        this.showDetail = true
        await this.$refs.updateForm.getData(row)
        await this.$refs.detailGrid.loadGrid(row)
        this.currentRow = row
      }
    },
    async created() {
      api.config('config/' + this.moduleName + '/grid.json', {
      }).then( function(response){
        this.$refs.searchGrid.columns = response.data.data
        this.$refs.searchGrid.loadGrid('')
      }.bind(this))

      api.config('config/' + this.moduleName + 'Detail/grid.json', {
      }).then( function(response){
        this.$refs.detailGrid.columns = response.data.data
      }.bind(this))
    },
    components: {
      DodoGrid,
      CardComponent,
      DodoFormSearch,
      DodoFormAddNew,
      DodoEdit,
      DodoGridDetail
    }
  }
</script>
