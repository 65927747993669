<template>
  <form class="mt-5" ref="form" @submit.prevent = "submit" v-on:keydown.enter.prevent="submit">
    <dodoform purpose="search" :moduleName="this.moduleName"></dodoform>
    <b-field grouped>
      <div class="control">
        <b-button
          type="is-info"
          @click="search"
        >
          {{$t('en.search')}}
        </b-button>
      </div>
      <div class="control">
        <b-button
          type="is-info is-outlined"
          @click="reset"
        >{{$t('en.reset')}}
        </b-button>
      </div>
      <div class="control">
        <b-button
          type="is-danger"
          @click="addNewClick"
        >
          {{$t('en.add_new')}}
        </b-button>
      </div>
    </b-field>
    <hr class="mt-1 mb-3">
  </form>
</template>
<script>
  import Dodoform from '@/components/dodoComponents/dodoForm.vue'
  export default {
    name: 'dodoFormSearch',
    components: {
      Dodoform
    },
    methods: {
      addNewClick(){
        this.addNew()
      },
      reset(){
        const form = this.$refs.form
        for ( let i = 0; i < form.elements.length; i++ ) {
          const e = form.elements[i]
          e.value = ''
        }
      },
      search(){
        const object = {}
        const form = this.$refs.form
        for ( let i = 0; i < form.elements.length; i++ ) {
          const e = form.elements[i]
          if (e.name !== '') {
            object[e.name] = e.value
          }
        }
        const json = JSON.stringify(object)
        const formData = new FormData()
        formData.append('searchData', json)
        this.searchSubmit(formData)
      }
    },
    props: ['moduleName', 'searchSubmit', 'addNew']
  }
</script>
