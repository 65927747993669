<template>
  <div id="editTable">
    <CustomTable/>
  </div>
</template>
<style lang="scss">
  #editTable {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 10px;
  }
</style>
<script>
  import CustomTable from '@/components/CustomTable.vue'
  export default {
    name: 'fastPayOrder',
    components: {
      CustomTable
    }
  }
</script>
