<template>
  <form class="mt-5" ref="form" @submit.prevent = "submit" v-on:keydown.enter.prevent="submit">
    <dodoform :moduleName="this.moduleName" purpose="edit" ref="updateForm" ></dodoform>
    <b-field grouped>
      <div class="control">
        <b-button
          :label="$t('en.submit')"
          type="is-info"
          @click="submit" />
      </div>
      <div class="control">
        <b-button
          type="is-danger"
          @click="addNewClick"
        >
          {{$t('en.add_new')}}
        </b-button>
      </div>
    </b-field>
  </form>
</template>
<script>
  import Dodoform from '@/components/dodoComponents/dodoForm.vue'
  import api from '@/api.js'

  export default {
    name: 'dodoFormSearch',
    components: {
      Dodoform,
    },
    methods: {
      addNewClick(){
        this.$emit('addNewDetail', this.currentId)
      },
      async getData (id){
        const serverData = await api.get(this.moduleName + '/getById?id=' + id)
        const formData = this.$refs.updateForm.formData
        for (const x in formData) {
          const fieldName = formData[x].fieldName
          formData[x].value = serverData.data.list[0][fieldName]
        }
        this.$refs.updateForm.formData = formData
        this.currentId = id
      },
      submit: async function (){
        const form = this.$refs.form
        let isFormPassed = true
        const object = {}
        this.$refs.updateForm.$refs.formElement.forEach(function (item){
          if ( item.$children.length > 0 ){
            if ( !item.$children[0].checkHtml5Validity()){
              isFormPassed = true
            }
          }
        })
        if ( isFormPassed ) {
          for (let i = 0; i < form.elements.length; i++) {
            const e = form.elements[i]
            if (e.name !== '') {
              object[e.name] = e.value
            }
          }
          object['primaryKey'] = this.$refs.updateForm.key
          object['primaryKeyValue'] = this.currentId
          const json = JSON.stringify(object)
          const formData = new FormData()
          formData.append('postData', json)
          const resultData = await api.post(this.moduleName + '/updateData', formData)
          const msg = this.$t('en.' + resultData.data.message)
          this.$buefy.snackbar.open({
            message: msg,
            queue: false
          })
          this.searchSubmit()
        }
      }
    },
    data(){
      return {
        currentId: 0
      }
    },
    props: ['moduleName', 'searchSubmit']
  }
</script>
