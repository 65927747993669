<template>
  <form class="mt-5" ref="form">
    <div class="columns block is-multiline mb-0">
      <b-field   v-for="item in formData" :key="item.id"  :label="item.label" label-position="on-border"
                 :class="item.label!='' ? 'column is-6' : 'column is-6 p-0'"  expanded ref="formElement" >
        <b-input v-if="item.formType==='input'" :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName" :v-bind="item.fieldName"
                 :ref="item.fieldName" :value="item.value" :required="item.searchType==null && item.required!=null ? true : false"></b-input>
        <b-upload  v-if="item.formType==='file'" :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName" :v-bind="item.fieldName"
                 :ref="item.fieldName" :value="item.value" :required="item.searchType==null && item.required!=null ? true : false"  class="file-label">
                      <span class="file-cta mr-1">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label">上傳文件</span>
                      </span>
                      <span class="file-cta" v-if="item.value">
                        <b-icon class="file-icon" icon="download"></b-icon>
                        <span class="file-label">下載文件</span>
                      </span>
        </b-upload >
        <b-select v-if="item.formType==='select'" :placeholder="item.label"
                  :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName" :value="item.value">
          <option v-for="data in item.data" :key="data.value" :value="data.value">{{data.name}}</option>
        </b-select>
        <load-option v-if="item.formType==='dSelect'" :url="item.url"  :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName" :value="item.value"></load-option>
        <b-datepicker v-if="item.formType==='date' && item.value!=null" :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName"  icon="calendar-today"  locale="en-US"  :value="new Date(item.value)"></b-datepicker>
        <b-datepicker v-if="item.formType==='date' && item.value==null" :name="item.searchType!=null ? item.fieldName+'--'+item.searchType : item.fieldName"  icon="calendar-today"  locale="en-US" ></b-datepicker>
        <input v-if="item.formType==='id' && item.value!=null" type="hidden" :name="item.fieldName" :value="item.value">
        <input v-if="item.formType==='parentId'" type="hidden" :name="item.fieldName" :value="item.value">
      </b-field>
    </div>
    <b-field grouped v-if="this.formType!=='addNew'">
      <div v-if="this.formType!='search'">
        <b-upload v-model="file1" class="file-label mr-2" >
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">單據</span>
            </span>
          <span class="file-name" v-if="file1">
                {{ file1.name }}
            </span>
        </b-upload>
        <b-upload v-model="file2" class="file-label mr-2">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">合同</span>
            </span>
          <span class="file-name" v-if="file2">
                {{ file2.name }}
            </span>
        </b-upload>
        <b-upload v-model="file3" class="file-label mr-2">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">包裝文件</span>
            </span>
          <span class="file-name" v-if="file3">
                {{ file3.name }}
            </span>
        </b-upload>
        <b-upload v-model="file4" class="file-label mr-2">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">到貨提單</span>
            </span>
          <span class="file-name" v-if="file4">
                {{ file4.name }}
            </span>
        </b-upload>
        <b-upload v-model="file5" class="file-label mr-2">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">供應商發票</span>
            </span>
          <span class="file-name" v-if="file5">
                {{ file5.name }}
            </span>
        </b-upload>
      </div>
    </b-field>
    <b-field grouped v-if="this.formType!=='addNew'">
      <div class="control">
        <b-button
          type="is-info"
          @click="submit"
        >
          <span v-if="this.formType=='search'" ref="searchBtn">{{$t('en.search')}}</span>
          <span v-else>{{$t('en.submit')}}</span>
        </b-button>
      </div>
        <b-upload v-model="contractFile" class="file-label mr-2" v-if="this.formType==='search'" @input="contractUpload">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label" ref="uploadContractField">{{$t('en.uploadContract')}}</span>
            </span>
          <span class="file-name" v-if="contractFile">
                {{contractFile.name}}
            </span>
        </b-upload>
      <div class="control" v-if="this.hasDetail">
        <b-button
          type="is-danger"
          @click="addNewClick"
        >
          {{$t('en.'+ this.newButtonLabel)}}
        </b-button>
      </div>
      <div class="control" v-if="downloadLink != null && this.$parent.fileList != null">
        <b-dropdown aria-role="list" >
          <template #trigger="{ active }">
            <b-button
              label="下載"
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>
          <b-dropdown-item v-if="this.$parent.fileList.invoiceFileId!=null" aria-role="listitem"  @click="downloadClick('invoice')">單據 </b-dropdown-item>
          <b-dropdown-item v-if="this.$parent.fileList.contractFileId!=null" aria-role="listitem" @click="downloadClick('contract')">合同</b-dropdown-item>
          <b-dropdown-item v-if="this.$parent.fileList.packingFileId!=null" aria-role="listitem" @click="downloadClick('packing')">包裝文件</b-dropdown-item>
          <b-dropdown-item v-if="this.$parent.fileList.billLadingFileId!=null" aria-role="listitem" @click="downloadClick('bill_landing')">到貨提單</b-dropdown-item>
          <b-dropdown-item v-if="this.$parent.fileList.saleInvoiceFileId!=null" aria-role="listitem" @click="downloadClick('sale_invoice')">供應商發票</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="control">
      <b-button
        type="is-info is-outlined"
        @click="reset"
      >
        {{$t('en.reset')}}
      </b-button>
    </div>
    </b-field>
    <hr v-if="this.formType!=='addNew'" class="mt-1 mb-3">
  </form>
</template>
<script>
  import api from '@/api.js'
  import loadOption from '@/components/General/loadOption.vue'
  export default {
    name: 'dodoForm',
    components: {
      loadOption
    },
    methods: {
      downloadClick: function(type){
        this.formData.forEach(function (item){
          if (item.fieldName === 'contractId'){
            window.open( api.publicURL + 'contract/downloadFile?id=' + item.value + '&type=' + type, '_blank' )
          }
        })
      },
      showDetailForm: async function(row, viewLink){
        if (viewLink === null){
          viewLink = this.viewLink
        }
        const resultData = await api.get(viewLink + '?id=' + row)
        this.formDetailData = resultData.data.master
        this.$parent.fileList = resultData.data.master
        this.loadFormData(resultData.data)
      },
      contractUpload: function(){
        this.$refs['searchBtn'].textContent = '上傳識別和涮新'
        this.$refs['uploadContractField'].textContent = '更換文件'
      },
      addNewClick(){
        this.$emit('addNewClick', this.formType)
      },
      async submit() {
        const form = this.$refs.form
        let isFormPassed = true
        this.$refs.formElement.forEach(function (item){
          if ( item.$children.length > 0 ){
              if ( !item.$children[0].checkHtml5Validity()){
                isFormPassed = false
              }
          }
        })
        if ( isFormPassed ) {
          let returnId = 0
          const object = {}
          for (let i = 0; i < form.elements.length; i++) {
            const e = form.elements[i]
            if (e.name !== '') {
              object[e.name] = e.value
            }
          }
          const json = JSON.stringify(object)
          const formData = new FormData()
          if (this.formType === 'search') {
            formData.append('searchData', json)
            if (this.contractFile !== null) {
              formData.append('contractFile', this.contractFile)
            }
            await this.submitForm(formData)
            this.reset()
          } else if (this.formType === 'detail' || this.formType === 'addNew') {
            formData.append('formData', json)
            formData.append('invoiceFile', this.file1)
            formData.append('contractFile', this.file2)
            formData.append('packingFile', this.file3)
            formData.append('billLadingFile', this.file4)
            formData.append('saleInvoiceFile', this.file5)
            const resultData = await api.post(this.updateLink, formData)
            returnId = resultData.data.result
            const msg = resultData.data.msg
            this.$emit('submitForm', '')
            this.$buefy.snackbar.open({
              message: this.$t('en.' + msg),
              queue: false
            })
            if (this.formType === 'detail') {
              this.showDetailForm(resultData.data.contractId, this.viewLink)
            }
          }
          return returnId
        } else {
          return 0
        }
      },
      reset(){
        const form = this.$refs.form
        for ( let i = 0; i < form.elements.length; i++ ) {
          const e = form.elements[i]
          e.value = ''
        }
        this.contractFile = null
        this.$refs['searchBtn'].textContent = '搜索'
        this.$refs['uploadContractField'].textContent = '上傳識別合同'
      }
    },
    data() {
      return {
        selected: new Date(),
        formDetailData: {},
        isLoading: false,
        isMainAddNewActive: false,
        file1: null,
        file2: null,
        file3: null,
        file4: null,
        file5: null,
        contractFile: null,
      }
    },
    props: ['submitForm', 'formData', 'formType', 'loadFormData', 'updateLink', 'viewLink', 'hasDetail', 'primaryKey', 'popType', 'newButtonLabel', 'downloadLink']
  }
</script>
