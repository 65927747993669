<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Record Detail</p>
    </header>
    <section class="modal-card-body pb-0 pt-2">
      <dodo-form :hasDetail="false" :formData="formConfig" :formType="'addNew'" :updateLink="addNewURL" ref="form" :viewLink="detailViewLink" :loadFormData="loadFormData"></dodo-form>
    </section>
    <footer class="modal-card-foot">
      <b-button
        label="Save"
        type="is-info"
        @click="saveData" />
      <b-button
        label="Close"
        @click="$emit('close')" />
    </footer>
  </div>
</template>
<script>
  import DodoForm from '@/components/General/form.vue'
  export default {
    name: 'popupform',
    props: ['formConfig', 'addNewURL', 'submitForm', 'detailViewLink', 'loadFormData'],
    methods: {
      async saveData(){
        const form = this.$refs.form.$refs.form
        // let returnId = 0
        const object = {}
        for ( let i = 0; i < form.elements.length; i++ ) {
          const e = form.elements[i]
          if (e.name !== '') {
            object[e.name] = e.value
          }
        }
        const json = JSON.stringify(object)
        this.loadFormData(json)

        // const resultData = await api.post(this.updateLink, formData)
        // this.$emit('submitForm', '')
        // this.$buefy.snackbar.open({
        //    message: resultData.msg,
        //    queue: false
        //  })
        // returnId = resultData.data.result
        // return returnId
      },
      showDetailForm(row){
        this.$refs.form.showDetailForm(row)
        // console.log(row)
      },
    },
    components: {
      DodoForm,
    }
  }
</script>
