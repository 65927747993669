<template>
  <section class="section is-main-section">
    <card-component
      :title="$t('en.client')"
      icon="ballot"
    ><dodo-form-add-new ref="addNewForm" :moduleName="moduleName" :searchSubmit="searchSubmit" ></dodo-form-add-new>
      <b-tabs type="is-boxed"  v-model="activeTab" >
        <b-tab-item :label="$t('en.list')" icon="table">
            <DodoFormSearch :moduleName="moduleName" :searchSubmit="searchSubmit" :addNew="addNew"></DodoFormSearch>
            <dodo-grid :searchData="searchResult" ref="searchGrid" :moduleName="moduleName" v-on:searchRowClick="searchRowClick"></dodo-grid>
        </b-tab-item>
        <b-tab-item :label="$t('en.detail')" icon="note-plus-outline" :visible="showDetail" >
          <dodo-edit ref="updateForm" :moduleName="moduleName" :searchSubmit="searchSubmit"></dodo-edit>
        </b-tab-item>
      </b-tabs>
    </card-component>
  </section>
</template>
<script>
  import CardComponent from '@/components/CardComponent.vue'
  import DodoFormSearch from '@/components/dodoComponents/dodoFormSearch.vue'
  import DodoFormAddNew from '@/components/dodoComponents/dodoFormAddNew.vue'
  import DodoGrid from '@/components/dodoComponents/dodoGrid.vue'
  import DodoEdit from '@/components/dodoComponents/dodoFormEdit.vue'
  import api from '@/api.js'

  export default {
    name: 'customer',
    data() {
      return {
        activeTab: 0,
        moduleName: this.$options.name,
        searchData: [],
        searchResult: [],
        showDetail: false,
      }
    },
    methods: {
        searchSubmit: async function(postData){
          this.$refs.searchGrid.loadGrid(postData)
        },
        addNew: function(){
          this.$refs.addNewForm.load()
        },
        close: function(){
          this.$refs.addNewForm.close()
        },
        searchRowClick: async function(row){
          this.activeTab = 1
          this.showDetail = true
          await this.$refs.updateForm.getData(row)
        }
    },
    async created() {
      api.config('config/' + this.moduleName + '/grid.json', {
      }).then( function(response){
        this.$refs.searchGrid.columns = response.data.data
        this.$refs.searchGrid.loadGrid('')
      }.bind(this))
    },
    components: {
      DodoGrid,
      CardComponent,
      DodoFormSearch,
      DodoFormAddNew,
      DodoEdit,
    }
  }
  </script>
