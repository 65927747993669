<template>
  <section class="section is-main-section">
    <card-component
      title="Inventory"
      icon="ballot"
    >
      <load-option :url="'config/optionTest.json'"></load-option>
      <b-tabs type="is-boxed">
        <b-tab-item :label="$t('en.list')" icon="table">
          <card-component class="has-table has-mobile-sort-spaced">
            <b-table :data="data" :columns="columns" :bordered="true" striped></b-table>
          </card-component>
        </b-tab-item>
        <b-tab-item label="Create" icon="note-plus-outline">
          <form  class="mt-5">
            <b-field grouped>
              <b-field label="名稱" label-position="on-border" expanded>
                <b-input  ></b-input>
              </b-field>
              <b-field label="地址"  label-position="on-border" expanded>
                <b-input  ></b-input>
              </b-field>
            </b-field>
            <b-field grouped>
              <b-field label="聯繫人" label-position="on-border" expanded>
                <b-input  ></b-input>
              </b-field>
              <b-field label="聯繫方式"  label-position="on-border" expanded>
                <b-input  ></b-input>
              </b-field>
            </b-field>

            <b-field >
              <b-field grouped>
                <div class="control">
                  <b-button
                    native-type="submit"
                    type="is-info"
                  >
                    Submit
                  </b-button>
                </div>
                <div class="control">
                  <b-button
                    type="is-info is-outlined"
                  >
                    Reset
                  </b-button>
                </div>
              </b-field>
            </b-field>
          </form>
          <hr>
          <sample-data></sample-data>
        </b-tab-item>
        <b-tab-item :label="$t('en.detail')" icon="note-text-outline"></b-tab-item>
      </b-tabs>
    </card-component>
  </section>
</template>

<script>
  import CardComponent from '@/components/CardComponent.vue'
  import sampleData from '@/ERP/SampleData.vue'
  import loadOption from '@/components/General/loadOption.vue'

  export default {
    name: 'inventory',
    data() {
      return {
        data: [
          { 'id': 'XD-EP2022-02-0039', 'first_name': 'Jesse', 'last_name': 'Ukraine braces for Russian', 'date': '2016-10-15 13:43:27', 'gender': 'Male' },
          { 'id': 'XD-EP2022-02-0041', 'first_name': 'John', 'last_name': 'Ukraine announced', 'date': '2016-12-15 06:00:53', 'gender': 'Male' },
          { 'id': 'XD-EP2022-02-0051', 'first_name': 'Americans will fully', 'last_name': 'Commit changes', 'date': '2016-12-15 06:00:53', 'gender': 'Male' },
        ],
        columns: [
          {
            field: 'id',
            label: '單據編號',
            headerClass: 'customer-table-header',
            width: '200',
            cellClass: 'customer-table-cell ',
          },
          {
            field: 'first_name',
            label: '往來單位',
            headerClass: 'customer-table-header',
          },
          {
            field: 'last_name',
            label: '出貨倉庫',
            headerClass: 'customer-table-header',
          },
          {
            field: 'date',
            label: '單據日期',
            centered: true,
            headerClass: 'customer-table-header',
          },
          {
            field: 'gender',
            label: '經手人',
            headerClass: 'customer-table-header',
          }
        ]
      }},
    components: {
      CardComponent,
      sampleData,
      loadOption,
    }
  }
</script>
