<template>

  <div class="base-container">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th scope="col">訂單號</th>
        <th scope="col">合同號</th>
        <th scope="col">產品名稱</th>
        <th scope="col">數量</th>
        <th scope="col">單價</th>
        <th scope="col">總價</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="record in records" :key="record.detail_id">
        <td v-show="record.inter_sale_id === undefined">
          <b-input type="text" class="form-control" v-model="record.inter_sale_code" ></b-input>
        </td>
        <td v-show="record.inter_sale_id === undefined">
          <b-input type="text" class="form-control" v-model="record.contract_no" ></b-input>
        </td>
        <td style="padding-top:15px" v-show="record.inter_sale_id !==undefined">
          {{record.inter_sale_code}}
        </td>
        <td style="padding-top:15px" v-show="record.inter_sale_id !==undefined">
          {{record.contract_no}}
          <input type="hidden" id="interSaleId" name="interSaleId" v-model="record.inter_sale_id" >
        </td>
        <td v-show="record.inter_sale_id ==undefined">
          <!--<dodo-select :url="'selectData/getProducts'" ref="fieldValue" :value="record.fieldValue" ></dodo-select>-->
          <v-select :options="list" label="name" :reduce="name => name.id" ref="searchSelect" v-model="record.product_id"></v-select>
        </td>
        <td v-show="record.inter_sale_id !==undefined">
          <b-input type="text" class="form-control" v-model="record.product_name" ></b-input>
        </td>
        <td>
          <b-input type="text" class="form-control" v-model="record.quantity" ></b-input>
        </td>
        <td>
          <b-input type="text" class="form-control" v-model="record.unit_price" ></b-input>
        </td>
        <td style="padding-top:15px">{{ record.quantity * record.unit_price}}</td>
        <td>  <b-button @click="deleteRow (record.id,record.idd,record.detail_id)">刪除</b-button></td>
      </tr>
      </tbody>
    </table>
    <span style="font-size: x-large; font-weight: bold">總價 : {{getTotal}}</span>
  </div>
</template>

<style lang="scss">
  .base-container {
    with: 100%;
    padding: 0 24px;
  }
</style>

<script>
  import api from '@/api.js'
  import dodoSelect from '@/components/dodoComponents/dodoOption.vue'
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css'
  export default {
    props: ['paymentId', 'columnsSet', 'moduleName'],
    name: 'custom-table',
    data: function () {
      return {
        records: [],
        product: 'abc',
        qty: 0,
        price: 0,
        list:[],
      }
    },
    computed: {

      getTotal: function(){
        let total = 0
        for (let i = 0; i < this.records.length; i++) {
          total = total + parseInt(this.records[i].quantity) * parseFloat(this.records[i].unit_price)
        }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        return formatter.format(total)
      }
    },
    methods: {
      /*deleteRow(id){
        for (let j = 0; j < this.records.length; j++) {
          if (this.records[j].id === id){
            this.records.splice(j, 1)
          }
        }
      },*/

      deleteRow: function(id,idd,detail_id){
        if (detail_id===undefined){
          if (idd===undefined){
            for (let j = 0; j < this.records.length; j++) {
              if (this.records[j].id === id){
                this.records.splice(j, 1)
              }
            }
          }else {
            for (let j = 0; j < this.records.length; j++) {
              if (this.records[j].id === idd){
                this.records.splice(j, 1)
              }
            }
          }
        } else {
          this.$buefy.dialog.confirm({
            title: this.$t('en.deleting'),
            message: '<span style>' + this.$t('en.confirm_delete') + '</span>',
            confirmText: this.$t('en.deleting'),
            cancelText: this.$t('en.cancel'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.confirmDelete(detail_id)
          })
        }

      },
      async confirmDelete(id){
        await api.get('payOrderDetail/deleteProduct' + '?detail_id=' + id);
        this.$buefy.snackbar.open({
          message: 'Done',
          queue: false
        })
        this.getProductByPaymentId(this.paymentId)
      },
      addNewClick(pushData){
        if (pushData){
          for (let i = 0; i < pushData.length; i++) {
            let elementExist = false
            for (let j = 0; j < this.records.length; j++) {
              if (this.records[j].id === pushData[i].id){
                elementExist = true
              }
            }
            if (!elementExist){
              this.records.push(pushData[i])
            }
          }
        } else {
          this.records.push({inter_sale_code:'',contract_no:'',quantity:0,unit_price:0,id:this.records.length,product_id:null})
        }
        console.log(this.records)
      },
      saveClick: async function(){
        console.log(this.$refs['productName'])
        const formData = new FormData()
        formData.append('postData', JSON.stringify(this.records))
        formData.append('paymentId', this.paymentId)
        await api.post(this.moduleName + '/saveDetail', formData)
        this.getProductByPaymentId(this.paymentId)
      },
      async getProductByPaymentId (id){
        const serverData = await api.get(this.moduleName + '/getProductByPaymentId?id=' + id)
        this.records = serverData.data.list;
      },
    },
    created(){
      api.get('selectData/getProducts', {
      }).then( function(response){
        this.list = response.data
      }.bind(this))
    },
    components:{
      dodoSelect,
      vSelect
    },
  }
</script>
